.studentListTable table {
  padding: 0px !important;
  border: none;
  border-collapse: collapse;
}
.studentListTable table th {
  background-color: none;
}
.filterBlock {
  background-color: #2d2d2d;
  margin-top: 0 !important;
  padding-bottom: 5px;
}
.studentNameList {
  font-size: small;
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
}
.searchIcon {
  position: absolute;
  right: 5px;
  bottom: 2px;
  background-color: transparent;
}
.filterBlock {
  background: linear-gradient(to right, #02476d, #01598a);
  margin-top: 0 !important;
  padding-bottom: 5px;
}
.paginationButtons {
  padding: 3px 5px;
  background-color: transparent;
  color: #f8931f;
  font-weight: bold;
  /* border: 0.5px solid green; */
  border-radius: 20px;
  display: flex;
  align-items: center;
}