.mainBlock {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainBlock > article {
  background-color: #efefef;
  box-shadow: 0px 4px 3px 4px rgb(25 118 210 / 50%);
  height: auto;
  padding: 2%;
  width: 50%;
  border-radius: 5px;
}

.mainBlock > article > h2 {
  text-align: center;
  color: #27b;
}

.mainBlock > article > form {
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@media (max-width: 720px) {
  .mainBlock > article {
    width: 84%;
    box-shadow: 1px 1px 3px 2px lightblue;
    border-radius: 10px;
  }
}
.instructions {
  /* list-style-type: none; */
  padding-left: 0;
}

.instructions li {
  margin-bottom: 10px;
}
