.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  text-align: center;
  padding: 20px;
}

.formBox {
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.BoxStyle {
  display: flex;
  gap: 20px;
}
.formHeading {
  font-size: 24px;
  width: 100%;
  color: #02476d;
  font-size: 26px !important;
  margin-bottom: 40px !important;
}
.formField {
  margin-bottom: 20px !important;
  /* margin-right: 20px !important; */
}
.selectField {
  margin-bottom: 20px !important; /* Adjust this value to control the space between select fields */
}
.uploadLabel {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust as needed */
  font-size: 16px; /* Adjust label font size */
  color: rgba(0, 0, 0, 0.87); /* Adjust label color */
}

.uploadVideo {
  /* border: 1px solid gray !important;
  border-radius: 5px !important; */
  margin-bottom: 8px !important;
}
.uploadVideo > label {
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
}
.uploadVideo > label > input {
  padding: 5px;
}
