#uploaderCardsBlock {
  padding: 0 5vw;
  width: 100%;
  background-color: #fff;
}

#uploaderCardsBlock > article {
  width: 100%;
  /* background-color: #FEF4EA; */
  margin: 0 auto;
  padding: 20px;
}

#formSubFields {
  display: flex;
  gap: 20px;
  width: 100%;
  margin: 20px 0 20px 10px;
  justify-content: center;
  align-items: center;
}

#formSubFields > div {
  width: 24%;
}
.BoxStyle {
  display: flex;
  gap: 20px;
}
.progress {
  background: linear-gradient(to left, #f2709c, #ff9472) !important;
  box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c !important;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  font-family: "Times New Roman", Times, serif;
  word-spacing: 5px;
  width: 300px;
  border: 1px solid rgba(128, 128, 128, 0.51);
}

.progressDone {
  background: linear-gradient(to left, #f2709c, #ff9472) !important;
  box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c !important;
  border-radius: 20px;
  color: #fff;
  text-shadow: 1px 1px #161616;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 1;
  transition: 1s ease 0.3s;
}

@media (max-width: 800px) {
  #uploaderCardsBlock {
    padding: 0 1vw;
  }

  #formSubFields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: 20px 0;
    padding-left: 15px;
  }

  #formSubFields > div {
    width: 80% !important;
  }
}
