.RightBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.formblock {
  width: 500px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(115, 133, 159, 0.5),
    0px 1px 10px 0px rgba(115, 133, 159, 0.5);
}
.formGroup {
  margin-bottom: 15px;
}
.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid gray !important;
  font-size: 20px;
  padding: 8px;
  color: #01598a;
  font-size: 25px;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
.inputField {
  width: 96%;
  padding: 15px 8px;
  font-size: 16px;
  border: 2px solid #1976d2;
  border-radius: 4px;
  outline: none;
}
.label {
  display: block;
  margin-bottom: 5px;
}
.submitButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: #01598a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}

.Headleable {
  padding: 5px;
  font-weight: 500;
}
.Headleable1 {
  border-color: gray;
}
