#MainUserBlock {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-wrap: wrap;
}

#MainUserBlock > .LeftSidebar {
  margin-top: 8vh;
  flex: 0 0 20%;
  background-color: #efefef;
  color: #fff;
  font-weight: bold;
  height: 91vh;
  overflow: auto;
}

#MainUserBlock > .RightVideoList {
  margin-top: 65px;
  flex: 0 0 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efefefef;
}

.noVideos {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  flex-direction: column;
}

.noVideos > h3 {
  color: #01598a;
}

.noVideos > img {
  height: 150px;
}

.selectFeatureInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectFeatureInfo > h3 {
  color: #01598a;
}

.closeButton {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 30px;
  padding: 2px;
  border: none;
  z-index: 9;
}

.videoCardUser {
  width: 345px;
}

/* Media query for responsiveness */
@media (max-width: 528px) {
  #MainUserBlock {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  #MainUserBlock > .LeftSidebar {
    width: 40%;
  }

  .RightVideoList {
    flex: 0 0 65% !important;
  }

  .videoCardUser {
    width: 95%;
  }

  .MainVideoBlockUser > h2 {
    font-size: medium;
  }

  .MainVideoBlockUser1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 88vh;
  }
  .noVideos > h3 {
    font-size: small;
    width: 80%;
    text-align: center;
  }
}
