.listApp {
  width: 100%;
  overflow: hidden;
}

.listApp > nav {
  /* background-color: #bbbaba; */
  padding: 10px 0;
  position: absolute;
  right: 0;
  left: 0;
  height: 5vh;
  display: flex;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  margin-top: 8vh;
  background: seashell;
  z-index: 999;
}

.listApp > nav > span:first-child {
  flex-basis: 2vw;
  display: flex;
  align-items: center;
}

.listApp > nav .icon-wrapper {
  padding: 6.5px 15px;
  display: inline-block;
}

.listApp > nav .icon {
  /* height: 1.2em; */
  transition: transform 1s;
  /* cursor: pointer; */
}

.listApp > nav .icon:hover {
  /* background-color: #2677b0; */
  fill: rgb(154, 56, 10);
  transform: rotate(360deg);
}

.listApp > nav .icon:hover::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 2px solid #fff;
  border-radius: 50%;
}

.listApp > aside {
  overflow-x: hidden;
  width: 0;
  position: absolute;
  top: 3em;
  left: 0;
  bottom: 0;
  /* background-color: #2d2d2d; */
  background: linear-gradient(to right, #02476d, #01598a);
  transition: width 0.5s ease-in-out;
  top: 7rem;
}

.listApp > aside ul {
  list-style: none;
  padding: 0;
}

.listApp > aside ul li {
  transition: transform 0.7s;
}

.listApp > aside ul li i {
  margin-right: 2em;
}

.listApp > aside ul li a {
  text-decoration: none;
  color: #d3d3d3;
  display: flex;
  align-items: center;
  color: #d3d3d3;
  padding: 8px 0 8px 22px;
  font-weight: 400;
  cursor: pointer;
}

.listApp > aside ul li .active {
  color: #fff;
  border: none !important;
}

.listApp > aside ul li .active i svg {
  fill: #ffb84c !important;
  height: 1.2em !important;
}

.listApp a {
  text-decoration: none;
}

.listApp > aside h4 {
  margin: 20px 0;
  cursor: pointer;
  text-align: center;
  color: #fff;
}

.listApp > aside ul li:hover {
  transform: translateX(8px);
  color: #ddd !important;
  /* border-left: 5px solid #ddd; */
}

.listApp .drawerMin {
  width: 3vw;
}

.listApp .drawerOpen {
  width: 13vw;
  margin-top: 7vh;
}

.listApp main {
  width: 100%;
  position: absolute;
  top: 60px;
  padding: 20px;
  margin-left: 0;
  bottom: 0;
  overflow-y: auto;
  transition: margin-left 0.5s ease-in-out;
}

.listApp .mainMin {
  width: 97vw;
  margin-left: 3vw;
}

.listApp .mainOpen {
  width: 87vw;
  margin-left: 13vw;
}
.listApp .mainClose {
  width: 87vw;
  margin-left: 12vw;
  width: 79vw;
  margin-left: 18vw;
  margin-top: 10vh;
}

.listApp .drawerMin h4 > i {
  display: inline-block;
  font-size: x-large;
  font-weight: 900;
}

.listApp .drawerMin h4 > span {
  display: none;
}

.listApp .drawerOpen h4 > i {
  display: none;
}

.listApp .drawerOpen h4 > span {
  display: inline-block;
  text-decoration: underline;
  color: #f8931f;
}

.listApp .navContent {
  display: flex;
  width: 0%;
  color: #111;
  align-items: center;
  justify-content: flex-end;
  margin: 0 50px;
  gap: 3%;
}

.listApp .navContent h4 {
  text-align: center !important;
  flex-basis: 100%;
  font-size: x-large;
  font-weight: bolder;
  padding-left: 20px;
  color: #2d2d2d;
}

.unblockBtn {
  height: 100%;
  display: flex;
  align-items: center;
}

.unblockBtn button {
  padding: 7px 6px;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  color: #2d2d2d;
  background-color: #d78022;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .listApp > nav {
    /* margin-top: 8vh; */
    height: 5vh;
    background: seashell;
    z-index: 999;
    justify-content: space-between;
  }

  .listApp > nav i {
    margin: auto 0 auto 10px;
    padding: 6px;
    font-size: medium;
    transition: transform 1s;
    cursor: pointer;
  }

  .listApp > nav i:hover {
    background-color: #2d2d2d;
    color: #f8931f;
    border-radius: 16px;
    transform: rotate(360deg);
  }

  .listApp > aside {
    top: 7vh;
    /* top: 7rem; */
  }

  .listApp .drawerMin {
    width: 5vw;
  }

  .listApp .drawerOpen {
    width: 25vw;
    margin-top: 8vh;
  }

  .listApp main {
    width: 100%;
    position: absolute;
    top: 7vh;
    margin-left: 0;
    bottom: 0;
    overflow-y: auto;
    transition: margin-left 0.5s ease-in-out;
  }

  .listApp .mainMin {
    width: 95vw;
    margin-left: 5vw;
  }

  .listApp .mainOpen {
    width: 79vw;
    margin-left: 18vw;
    margin-top: 10vh;
  }
}

.listApp .navButton {
  transition: 0.5s all ease-in-out !important;
  cursor: pointer;
  font-weight: bolder;
}

.listApp .navButton:hover {
  color: #f8931f !important;
  font-weight: bolder;
  transition: 0.5s all ease-in-out !important;
}

.listApp .searchDropDown {
  list-style: none !important;
  background-color: #eeefef !important;
  width: 30vw !important;
  max-height: 60vh !important;
  position: absolute;
  z-index: 4;
  overflow: hidden;
  display: none;
  border-radius: 5px;
}
/* @media (min-width: 1601px) and (max-width: 2500px) {

} */
@media (min-width: 1601px) and (max-width: 2000px) {
  .listApp .mainMin {
    width: 90%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listApp main {
    width: 90%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .listApp main > section {
    width: 50%;
  }
  .listApp .drawerOpen {
    width: 13vw;
    margin-top: 3rem;
  }
  .listApp .drawerOpen {
    width: 22vw;
    margin-top: 1rem;
  }
}

@media (min-width: 2001px) and (max-width: 2800px) {
  .listApp .drawerOpen {
    width: 19vw;
    margin-top: 3rem;
  }
}
/* .listApp .searchDropDown {
  list-style: none !important;
  background-color: #eeefef !important;
  width: 30vw !important;
  max-height: 60vh !important;
  position: absolute;
  z-index: 4;
  overflow: hidden;
  display: none;
  border-radius: 5px;
} */

.selectedMenu {
  background-color: rgba(0, 0, 0, 0.54);
}
