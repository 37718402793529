.container {
  height: 90vh;
  background: lightgray;
}

.paper {
  margin: auto;
  padding: 20px;
  max-width: 400px;
}

.textfield {
  margin-bottom: 20px !important;
}
.LoginButton {
  width: 100% !important;
  padding: 10px 5px !important;
  border-radius: 5px !important;
  background-color: #01598a !important;
}
.LoginButtonLoader{
  width: 100% !important;
  padding: 10px 5px !important;
  border-radius: 5px !important;
  background-color: #01598a !important;
}
.LoginButton:hover {
  background-color: #02476d !important;
}
.Heading {
  color: #01598a !important;
  text-align: center;
}
.iconeye > svg {
  font-size: 16px;
}
