.MainBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
}
#adminDashboardBlock {
  width: 100%;
}
.Navbar {
  background: linear-gradient(to right, #02476d, #01598a);
  position: fixed !important;
  z-index: 11 !important;
  height: 8vh;
}

.SideBarBlock > div > div {
  margin-top: 65px !important;
  width: 10%;
  box-shadow: 0px 0px 8px 0px;
  color: #02476d;
}
.SideBarBlock > div > div > ul > a {
  font-size: 20px;
}
.SideBarBlock > div > div > ul > a:hover {
  background-color: #42b3f08d;
}
.BottomBlock {
  /* display: flex; */
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.leftPart {
  flex: 0 0 10%;
}
.RightPart {
  margin-top: 65px !important;
  flex: 1;
  padding: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88vh;
}
.dropdown {
  /* margin-left: 50px;
  width: 200px; */
  display: flex;
  gap: 5px;
}
.SelectDrop {
  width: 200px;
}
.VideoListBlock {
  margin-top: 15px;
  /* margin-top: 20px !important; */
}
.SelectedVideoBlock {
  margin: 10px 0px;
}
.CardBlock {
  height: 300px !important;
  margin-top: 20px !important;
}
.RightSideBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* height: 90vh; */
}
.searchDropDown {
  list-style: none !important;
  background-color: #eeefef !important;
  width: 30vw !important;
  max-height: 60vh !important;
  position: absolute;
  z-index: 4;
  overflow: hidden;
  display: none;
  border-radius: 5px;
}
/*---------------------- Search Css--------------------------- */
/* user list block start here */
.SearchBlock {
  /* display: flex; */
}
#BatchListBlock {
  width: 100%;
  margin: 0 auto;
  border: 2px solid black;
}

.heading {
  width: 100%;
  background-color: rgba(91, 91, 231, 0.813);
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
  padding: 5px 0;
}

.batchInfo {
  display: flex;
  width: 75%;
  margin: 0 auto;
  justify-content: space-between;
}

.batchInfo > h3 {
  color: rgba(91, 91, 231, 0.813);
}

.filterBlock {
  background-color: #2d2d2d;
  margin-top: 0 !important;
  padding-bottom: 5px;
}

.studentNameList {
  font-size: small;
  padding: 8px;
  border: 1px solid #02466c70;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
}

.searchIcon {
  position: absolute;
  right: 5px;
  bottom: 0px;
  background-color: transparent;
}

.previousPageButton {
  position: absolute;
  left: 5%;
  bottom: 1.2%;
  color: #f8931f;
  border: 2px solid #f8931f;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
}

.nextPageButton {
  position: absolute;
  right: 5%;
  bottom: 1.2%;
  color: #f8931f;
  border: 2px solid #f8931f;
  border-radius: 10px;
  background-color: transparent;
  cursor: pointer;
}

.batchCode {
  max-height: 100px;
  overflow-y: auto;
}

/* user list block ends here */

/* feedback */
.filterBtn {
  background-color: #f8931f;
  /* color: #fff; */
  border: none;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
}

.filterBtn:hover {
  background-color: #fdaa4b;
}

.clearFilterBtn {
  /* background-color: gray; */
  border: none;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
}

/* Logout alert */
.logoutAlertBlock {
  background-color: #efefef;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  min-width: 350px;
  height: 200px;
  border: 3px solid #393838;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin: 0;
  align-items: center;
  box-shadow: 0px 0px 0px 1000px rgba(15, 12, 11, 0.493);
}

.logoutAlertBlock > header {
  height: 10%;
  width: 100%;
}

.logoutAlertBlock > h2 {
  color: #f8931f;
  height: 30%;
}

.logoutAlertBlock > main {
  height: 20%;
  color: #111;
}

.logoutAlertBlock > div {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 10%; */
  gap: 10%;
}

.logoutAlertBlock > div > button {
  width: 35%;
  height: 60%;
  font-weight: bold;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.logoutAlertBlock > div > button:first-child {
  border: 1px solid #f8931f;
  color: #f8931f;
  background-color: #efefef;
}

.logoutAlertBlock > div > button:last-child {
  border: 1px solid #f8931f;
  background-color: #f8931f;
  color: #efefef;
}

.logoutAlertBlock > div > button:first-child:hover {
  transition: 0.5s all ease-in-out;
  background-color: #f8931f;
  color: #efefef;
}

.logoutAlertBlock > div > button:last-child:hover {
  transition: 0.5s all ease-in-out;
  color: #f8931f;
  background-color: #efefef;
}
