#headerBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10vh;
  background-color: #2677b0;
}

#headerBlock nav {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10%;
}

#headerBlock nav figure {
  flex-basis: 20%;
}

#headerBlock nav figure a {
  color: white;
  text-decoration: none;
  font-size: 3.5vh;
  font-weight: bold;
}

#headerBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 70%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#headerBlock nav ul li a {
  color: #fff;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

#headerBlock nav ul li a:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

#preview {
  height: 4vh;
  object-fit: contain;
  border-radius: 50%;
}

/* for Admin Header */
#adminHeaderBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 8vh;
  /* background-color:#D6DBDF; */
  background: linear-gradient(to right, #02476d, #01598a);
}

#adminHeaderBlock nav {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10%;
  justify-content: space-between;
}

#adminHeaderBlock nav figure {
  flex-basis: 20%;
}

#adminHeaderBlock nav figure a {
  color: #f8931f;
  text-decoration: none;
  font-size: 3vh;
  font-weight: bold;
}

#adminHeaderBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 70%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#adminHeaderBlock nav ul li a {
  color: #fff;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: 2vh;
  font-weight: 500;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

#adminHeaderBlock nav ul li a:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

/* For Student Header */
#studentHeaderBlock {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 10vh;
  /* background-color:#D6DBDF; */
  background-color: #092c40;
}

#studentHeaderBlock nav {
  width: 90%;
  height: inherit;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 10%;
}

#studentHeaderBlock nav figure {
  flex-basis: 20%;
}

#studentHeaderBlock nav figure a {
  color: #dfdfdf;
  text-decoration: none;
  font-size: 3.5vh;
  font-weight: bold;
}

#studentHeaderBlock nav ul {
  float: right;
  width: 100%;
  height: inherit;
  flex-basis: 70%;
  list-style: none;
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: flex-end;
}

#studentHeaderBlock nav ul li a {
  color: #dfdfdf;
  display: inline-block;
  height: inherit;
  width: 100%;
  padding: 0.4em;
  font-size: 2vh;
  text-decoration: none;
  transition: 0.3s all ease-in-out;
}

#studentHeaderBlock nav ul li a:hover {
  transform: scale(1.01);
  transition: 0.3s all ease-in-out;
  font-weight: 700;
}

@media (max-width: 700px) {
  #headerBlock {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 8vh;
    background-color: #2677b0;
  }

  #headerBlock nav figure a {
    font-size: 3vh;
  }

  #headerBlock nav ul li a {
    font-size: 1.8vh;
  }

  #studentHeaderBlock {
    height: 8vh;
  }

  #studentHeaderBlock nav figure a {
    font-size: 3vh;
  }

  #studentHeaderBlock nav ul li a {
    font-size: 1.6vh;
  }

  #preview {
    height: 25px;
  }

  #adminHeaderBlock {
    height: 8vh;
  }

  #adminHeaderBlock nav figure a {
    font-size: 3vh;
  }

  #adminHeaderBlock nav ul li a {
    font-size: 1.8vh;
  }
}
#Logo {
  font-size: 20px;
  font-weight: 800;
}
.NavLinkBlock {
}
