.treeContainer {
  width: 100%;
  /* padding: 16px; */
}

.treeItemLabel {
  padding: 8px;
  cursor: pointer;
  font-weight: 700;
  /* color: #008394; */
  color: #fff;

  /* color: darkorange; */
}
.treeItemLabelfea {
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #36ce79;
}
.treeItemLabelsub1 {
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  /* color: #4e342e; */
  color: #fff;
}
.treeItemLabelsub2 {
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
}
.treeItemLabelsub3 {
  padding: 8px;
  cursor: pointer;
  font-weight: 600;
  color: #fff;
}
.treeItemIcon {
  margin-right: 8px;
}

.Heading {
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 520px) {
  .treeItemLabel {
    font-size: small;
  }
}
.lablename {
  font-size: 10px;
  color: #f2709c;
}
.lableFeature {
  font-size: 10px;
  color: burlywood;
}
.lablelevel111 {
  font-size: 10px;
  color: #38bb72;
}
.lablelevel2 {
  font-size: 10px;
  color: rgb(25 210 200);;
}

.lablelevel3 {
  font-size: 10px;
  color: #ff9742;
}
